<template>
    <div style="width: 100%;">
        <header class="my-container py-6 flex justify-between align-center">
        <a :href="localePath('/')">
            <img src="/logo.png" alt="header logo" />
        </a>
        <div class=" align-center gap-6  md:flex">
            <div class="flex align-center">
                <ApplicationLanguage />
                <div style="height: 100%; border-left: 1px solid #D9D9D9;" class="mr-3 ml-2"></div>
                <div class="custom-select" tabindex="0" @blur="open = false">
                    <div class="selected" :class="{ open: open }" @click="open = !open">
                        {{ currency }}
                    </div>

                    <div class="items bg-my-green" :class="{ selectHide: !open }" style="width: fit-content">
                        <div class="flex items-center gap-2 hover:text-my-green" v-for="(option, i) of currencies"
                            :key="i" @click="
                                currency = option.code;
                            open = false;
                            ">
                            {{ option.name }}
                            <span class="text-my-green">
                                {{ option.symbol }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </div>
</template>

<script setup>
const { locale, t } = useI18n()
const localePath = useLocalePath()

const { start, finish } = useLoadingIndicator()
const openLoginModal = useState('login')
const openRegistrationModal = useState('registration')
const rc = useRuntimeConfig()
const headers = computed(() => ({
    lang: locale.value
}))
const open = ref(false)
const currencies = ref()
const currency = useState('currency')
async function getCurrencies() {
    const res = await $fetch(rc.public.api + '/api/currency', {
        headers: headers.value
    })
    currencies.value = res.data.currencies
    if (!currency.value) {
        if (locale.value === 'en') {
            currency.value = 'USD'
        } else if (locale.value === 'uz') {
            currency.value = 'UZS'
        } else if (locale.value === 'ru') {
            currency.value = 'RUB'
        }
        if (!currencies.value.find(el => el.code === currency.value)) {
            currency.value = currencies.value[0].code
        }
    }
}
await getCurrencies()
watch(locale, getCurrencies)
watch(locale, (v) => {
    if (v === 'en') {
        currency.value = 'USD'
    } else if (v === 'uz') {
        currency.value = 'UZS'
    } else if (v === 'ru') {
        currency.value = 'RUB'
    }
})
</script>

<style lang="scss" module>
header {
    & img {
        height: 24px;
    }
}
</style>