<template>
    <UModal v-model="loginModal">
        <div class=" rounded-2xl overflow-hidden">
            <div class="relative p-8 bg-blue-dark-950 flex items-center justify-between text-white mb-4">
                <span>
                    {{ t('login') }}
                </span>
                <UIcon name="ri:close-fill" size="24" @click="loginModal = false"></UIcon>
            </div>
            <div class="p-8">
                <label for="ticket" class="mb-6 block">
                    <div class="mb-2 text-[14px]/[17px] inter">{{ t('yourLogin') }}</div>
                    <input id="ticket" type="text"
                        class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                        placeholder="Login (E-mail)" maxlength="13">
                </label>

                <label for="ticket" class="mb-6 block">
                    <div class=" mb-2 text-[14px]/[17px] inter">{{ t('yourPassword') }}</div>
                    <input id="ticket" type="text"
                        class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                        placeholder="Password" maxlength="13">
                </label>
                <div class="flex items-center justify-between mb-4">
                    <span>{{ t('resetPassword') }}</span>
                    <span @click="switchRegistration">{{ t('registration') }}</span>
                </div>
                <div>
                    <button
                        class="py-4 px-6 bg-my-green mx-auto block rounded-3xl text-white text-[14px]/[16px] inter transition-shadow button-green">{{ t('login') }}
                    </button>
                </div>
            </div>

        </div>
    </UModal>
</template>
<script setup>
const { t } = useI18n()
const loginModal = useState('login')
const registrationModal = useState('registration')
function switchRegistration() {
    loginModal.value = false;
    registrationModal.value = true
}
</script>