import { default as index0JFRABd1kRMeta } from "/root/new-qanotsharq-frontend/pages/index.vue?macro=true";
import { default as aboutoX1pM66TwQMeta } from "/root/new-qanotsharq-frontend/pages/about.vue?macro=true";
import { default as additionalYPUKCUtSz2Meta } from "/root/new-qanotsharq-frontend/pages/additional.vue?macro=true";
import { default as extra_45luggagejlg3KMdSgGMeta } from "/root/new-qanotsharq-frontend/pages/extra-luggage.vue?macro=true";
import { default as faqykO8TsOaKzMeta } from "/root/new-qanotsharq-frontend/pages/faq.vue?macro=true";
import { default as flightsEVn2gzOaqKMeta } from "/root/new-qanotsharq-frontend/pages/flights.vue?macro=true";
import { default as _91name_937tIDja5JSsMeta } from "/root/new-qanotsharq-frontend/pages/information/[name].vue?macro=true";
import { default as location_45selectionWqBVqUy07pMeta } from "/root/new-qanotsharq-frontend/pages/location-selection.vue?macro=true";
import { default as magazineZ4mTJSjtgYMeta } from "/root/new-qanotsharq-frontend/pages/magazine.vue?macro=true";
import { default as _91slug_93Ex2WHSV15vMeta } from "/root/new-qanotsharq-frontend/pages/news/[slug].vue?macro=true";
import { default as indexVx3uJF7M5mMeta } from "/root/new-qanotsharq-frontend/pages/news/index.vue?macro=true";
import { default as online_45tableCGjqbz61AWMeta } from "/root/new-qanotsharq-frontend/pages/online-table.vue?macro=true";
import { default as passenger_45categoriesi3G2s4PFjLMeta } from "/root/new-qanotsharq-frontend/pages/passenger-categories.vue?macro=true";
import { default as planespyCoYH9d95Meta } from "/root/new-qanotsharq-frontend/pages/planes.vue?macro=true";
import { default as privacy_45policypvUMm1EFfBMeta } from "/root/new-qanotsharq-frontend/pages/privacy-policy.vue?macro=true";
import { default as tariffszxoduf62D0Meta } from "/root/new-qanotsharq-frontend/pages/tariffs.vue?macro=true";
import { default as vacancygRbhvRGxnzMeta } from "/root/new-qanotsharq-frontend/pages/vacancy.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/root/new-qanotsharq-frontend/pages/index.vue")
  },
  {
    name: "about___ru",
    path: "/ru/about",
    component: () => import("/root/new-qanotsharq-frontend/pages/about.vue")
  },
  {
    name: "about___uz",
    path: "/uz/about",
    component: () => import("/root/new-qanotsharq-frontend/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/root/new-qanotsharq-frontend/pages/about.vue")
  },
  {
    name: "additional___ru",
    path: "/ru/additional",
    component: () => import("/root/new-qanotsharq-frontend/pages/additional.vue")
  },
  {
    name: "additional___uz",
    path: "/uz/additional",
    component: () => import("/root/new-qanotsharq-frontend/pages/additional.vue")
  },
  {
    name: "additional___en",
    path: "/en/additional",
    component: () => import("/root/new-qanotsharq-frontend/pages/additional.vue")
  },
  {
    name: "extra-luggage___ru",
    path: "/ru/extra-luggage",
    component: () => import("/root/new-qanotsharq-frontend/pages/extra-luggage.vue")
  },
  {
    name: "extra-luggage___uz",
    path: "/uz/extra-luggage",
    component: () => import("/root/new-qanotsharq-frontend/pages/extra-luggage.vue")
  },
  {
    name: "extra-luggage___en",
    path: "/en/extra-luggage",
    component: () => import("/root/new-qanotsharq-frontend/pages/extra-luggage.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    component: () => import("/root/new-qanotsharq-frontend/pages/faq.vue")
  },
  {
    name: "faq___uz",
    path: "/uz/faq",
    component: () => import("/root/new-qanotsharq-frontend/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/root/new-qanotsharq-frontend/pages/faq.vue")
  },
  {
    name: "flights___ru",
    path: "/ru/flights",
    component: () => import("/root/new-qanotsharq-frontend/pages/flights.vue")
  },
  {
    name: "flights___uz",
    path: "/uz/flights",
    component: () => import("/root/new-qanotsharq-frontend/pages/flights.vue")
  },
  {
    name: "flights___en",
    path: "/en/flights",
    component: () => import("/root/new-qanotsharq-frontend/pages/flights.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/root/new-qanotsharq-frontend/pages/index.vue")
  },
  {
    name: "index___uz",
    path: "/uz",
    component: () => import("/root/new-qanotsharq-frontend/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/root/new-qanotsharq-frontend/pages/index.vue")
  },
  {
    name: "information-name___ru",
    path: "/ru/information/:name()",
    component: () => import("/root/new-qanotsharq-frontend/pages/information/[name].vue")
  },
  {
    name: "information-name___uz",
    path: "/uz/information/:name()",
    component: () => import("/root/new-qanotsharq-frontend/pages/information/[name].vue")
  },
  {
    name: "information-name___en",
    path: "/en/information/:name()",
    component: () => import("/root/new-qanotsharq-frontend/pages/information/[name].vue")
  },
  {
    name: "location-selection___ru",
    path: "/ru/location-selection",
    component: () => import("/root/new-qanotsharq-frontend/pages/location-selection.vue")
  },
  {
    name: "location-selection___uz",
    path: "/uz/location-selection",
    component: () => import("/root/new-qanotsharq-frontend/pages/location-selection.vue")
  },
  {
    name: "location-selection___en",
    path: "/en/location-selection",
    component: () => import("/root/new-qanotsharq-frontend/pages/location-selection.vue")
  },
  {
    name: "magazine___ru",
    path: "/ru/magazine",
    component: () => import("/root/new-qanotsharq-frontend/pages/magazine.vue")
  },
  {
    name: "magazine___uz",
    path: "/uz/magazine",
    component: () => import("/root/new-qanotsharq-frontend/pages/magazine.vue")
  },
  {
    name: "magazine___en",
    path: "/en/magazine",
    component: () => import("/root/new-qanotsharq-frontend/pages/magazine.vue")
  },
  {
    name: "news-slug___ru",
    path: "/ru/news/:slug()",
    component: () => import("/root/new-qanotsharq-frontend/pages/news/[slug].vue")
  },
  {
    name: "news-slug___uz",
    path: "/uz/news/:slug()",
    component: () => import("/root/new-qanotsharq-frontend/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    component: () => import("/root/new-qanotsharq-frontend/pages/news/[slug].vue")
  },
  {
    name: "news___ru",
    path: "/ru/news",
    component: () => import("/root/new-qanotsharq-frontend/pages/news/index.vue")
  },
  {
    name: "news___uz",
    path: "/uz/news",
    component: () => import("/root/new-qanotsharq-frontend/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/root/new-qanotsharq-frontend/pages/news/index.vue")
  },
  {
    name: "online-table___ru",
    path: "/ru/online-table",
    component: () => import("/root/new-qanotsharq-frontend/pages/online-table.vue")
  },
  {
    name: "online-table___uz",
    path: "/uz/online-table",
    component: () => import("/root/new-qanotsharq-frontend/pages/online-table.vue")
  },
  {
    name: "online-table___en",
    path: "/en/online-table",
    component: () => import("/root/new-qanotsharq-frontend/pages/online-table.vue")
  },
  {
    name: "passenger-categories___ru",
    path: "/ru/passenger-categories",
    component: () => import("/root/new-qanotsharq-frontend/pages/passenger-categories.vue")
  },
  {
    name: "passenger-categories___uz",
    path: "/uz/passenger-categories",
    component: () => import("/root/new-qanotsharq-frontend/pages/passenger-categories.vue")
  },
  {
    name: "passenger-categories___en",
    path: "/en/passenger-categories",
    component: () => import("/root/new-qanotsharq-frontend/pages/passenger-categories.vue")
  },
  {
    name: "planes___ru",
    path: "/ru/planes",
    component: () => import("/root/new-qanotsharq-frontend/pages/planes.vue")
  },
  {
    name: "planes___uz",
    path: "/uz/planes",
    component: () => import("/root/new-qanotsharq-frontend/pages/planes.vue")
  },
  {
    name: "planes___en",
    path: "/en/planes",
    component: () => import("/root/new-qanotsharq-frontend/pages/planes.vue")
  },
  {
    name: "privacy-policy___ru",
    path: "/ru/privacy-policy",
    component: () => import("/root/new-qanotsharq-frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___uz",
    path: "/uz/privacy-policy",
    component: () => import("/root/new-qanotsharq-frontend/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/root/new-qanotsharq-frontend/pages/privacy-policy.vue")
  },
  {
    name: "tariffs___ru",
    path: "/ru/tariffs",
    component: () => import("/root/new-qanotsharq-frontend/pages/tariffs.vue")
  },
  {
    name: "tariffs___uz",
    path: "/uz/tariffs",
    component: () => import("/root/new-qanotsharq-frontend/pages/tariffs.vue")
  },
  {
    name: "tariffs___en",
    path: "/en/tariffs",
    component: () => import("/root/new-qanotsharq-frontend/pages/tariffs.vue")
  },
  {
    name: "vacancy___ru",
    path: "/ru/vacancy",
    component: () => import("/root/new-qanotsharq-frontend/pages/vacancy.vue")
  },
  {
    name: "vacancy___uz",
    path: "/uz/vacancy",
    component: () => import("/root/new-qanotsharq-frontend/pages/vacancy.vue")
  },
  {
    name: "vacancy___en",
    path: "/en/vacancy",
    component: () => import("/root/new-qanotsharq-frontend/pages/vacancy.vue")
  }
]