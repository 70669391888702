<template>
    <footer class="bg-gray-bg-50">
        <div class="my-container lg:flex">
            <div class="pt-[50px] pb-6 lg:pb-[90px] pr-[60px] border-b-2 lg:border-b-0 lg:border-r-2 ">
                <div class="text-blue text-[16px]/[24px] mb-[22px]">
                    {{ t('contacts') }}
                </div>
                <div class="text-[24px]/[29px] md:text-[26px]/[32px] font-semibold mb-6">
                    <a :href="`tel:+${contacts.primary_phone}`">
                        {{ formatPhoneNumber(contacts.primary_phone) }}
                    </a>
                </div>
                <div class="text-[24px]/[29px] md:text-[26px]/[32px] font-semibold mb-6">
                    <a :href="`tel:+${contacts.secondary_phone}`">
                        {{ formatPhoneNumber(contacts.secondary_phone) }}
                    </a>
                </div>


                <div class="text-gray-text text-[16px]/[20px] mb-8">
                    <a :href="`mailto:${contacts.email}`">
                        {{ contacts.email }}
                    </a>
                </div>
                <div class="flex gap-4">
                    <a class="flex p-4 bg-white rounded-full" :href="link.link" target="_blank"
                        v-for="link in contacts.links">
                        <img :src="link.icon" alt="link.name" class="w-6 h-6">
                    </a>
                </div>
            </div>
            <div
                class="grow flex flex-col lg:flex-row gap-6 lg:gap-0 justify-between text-[14px]/[17px] md:text-[16px]/[19px] text-gray-text pt-6 lg:pt-[50px] pb-[90px]">
                <div class="hidden lg:block">
                </div>
                <section aria-labelledby="services">
                    <h2 id="services" class="mb-5 text-[16px]/[19px] md:text-[24px]/[28px] text-black">{{ t('services')
                        }}
                    </h2>
                    <ul class="flex flex-col gap-3">
                        <li>
                            <NuxtLink to="https://booking.qanotsharq.com/websky_grs/#/search" class="hover:text-black"
                                target="blank">
                                {{ t('buyTicket') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/extra-luggage')" class="hover:text-black">
                                {{ t('excessLuggage') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/information/on-board-service')" class="hover:text-black">
                                {{ t('orderFood') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/information/choosing-a-place')" class="hover:text-black">
                                {{ t('placeChoose') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/information/check-in-for-the-flight')" class="hover:text-black">
                                {{ t('registration') }}
                            </NuxtLink>
                        </li>
                    </ul>
                </section>

                <section aria-labelledby="information">
                    <h2 id="information" class="mb-5 text-[16px]/[19px] md:text-[24px]/[28px] text-black">
                        {{ t('information') }}
                    </h2>
                    <ul class="flex flex-col gap-3">
                        <li>
                            <NuxtLink :to="localePath('/tariffs')" class="hover:text-black">
                                {{ t('tariffs') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/information/transportation-rules')" class="hover:text-black">
                                {{ t('shippingRules') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/information/baggage-transportation')" class="hover:text-black">
                                {{ t('luggageShipping') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/passenger-categories')" class="hover:text-black">
                                {{ t('passengerCategory') }}
                            </NuxtLink>

                        </li>
                        <li>
                            <NuxtLink :to="localePath('/faq')" class="hover:text-black">{{ t('aq') }}</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/news')" class="hover:text-black">{{ t('news') }}</NuxtLink>
                        </li>
                    </ul>
                </section>

                <section aria-labelledby="company">
                    <h2 id="company" class="mb-5 text-[16px]/[19px] md:text-[24px]/[28px] text-black">{{ t('company') }}
                    </h2>
                    <ul class="flex flex-col gap-3">
                        <li>
                            <NuxtLink :to="localePath('/about')" class="hover:text-black">{{ t('aboutUs') }}</NuxtLink>
                        </li>
                        <!-- <li><a href="#" class="">{{ t('pathline') }}</a></li> -->
                        <li>
                            <NuxtLink :to="localePath('/planes')" class="hover:text-black">{{ t('ourFleet') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/vacancy')" class="hover:text-black">{{
                                t('vacancy') }}</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/privacy-policy')" class="hover:text-black">
                                {{ t('confPolicy') }}
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="localePath('/magazine')" class="hover:text-black">
                                {{ t('ourJournals') }}
                            </NuxtLink>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
    </footer>
</template>

<script setup>
const localePath = useLocalePath()
const { t, locale } = useI18n()
const rc = useRuntimeConfig()
const headers = computed(() => ({
    lang: locale.value
}))
const res = await $fetch(rc.public.api + '/api/contacts', {
    headers: headers.value
})
const contacts = ref(res.data.contacts)
function formatPhoneNumber(phone) {
    return phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
}
</script>