<template>
    <div class="custom-select" tabindex="0" @blur="open = false">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            <img :src="`/${lang}.png`" alt="">
        </div>
        <div class="items bg-my-green" :class="{ selectHide: !open }">
            <div class="flex items-center gap-2 hover:text-my-green" v-for="(option, i) of options" :key="i" @click="
                lang = option.value;
            open = false;
            ">
                <img :src="`/${option.value}.png`" alt="">
                <div>
                    {{ option.label }}
                </div>
            </div>
        </div>
    </div>
    <!-- <USelect v-model="lang" :options="options" :aria-label="t('language')" :ui="config" variant="none" :padded="false">
        <template #leading>
            <img :src="`/${locale}.png`" class="cursor-pointer">
        </template>
</USelect> -->
</template>

<script setup>
const { locale, locales, t } = useI18n()
const router = useRouter()
const switchLocalePath = useSwitchLocalePath()
const lang = ref(locale.value);
const langLabels = ref({
    uz: "O'z",
    ru: 'Рус',
    en: 'Eng'
})
const options = computed(() => locales.value.map(l => ({
    label: langLabels.value[l.code],
    value: l.code,
    icon: l.code + '.png'
})))
const open = ref(false)


watch(() => lang.value, (v) => {
    router.push(switchLocalePath(v))
})
const config = reactive({
    wrapper: 'relative border-0',
    base: 'relative block w-0 disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0',
    form: 'form-select',
    rounded: 'rounded-md',
    placeholder: 'text-gray-400 dark:text-gray-500',

    leading: {
        padding: {
            '2xs': 'ps-7',
            xs: 'ps-8',
            sm: 'ps-9 px-2',
            md: 'ps-0',
            lg: 'ps-11',
            xl: 'ps-12',
        },
    },
    trailing: {
        padding: {
            '2xs': 'pe-7',
            xs: 'pe-8',
            sm: 'pe-2 pb-0',
            md: 'pe-0',
            lg: 'pe-11',
            xl: 'pe-12',
        },
    },
    color: {
        white: {
            outline: 'shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white',
        },
        gray: {
            outline: 'shadow-sm bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-white',
        },
    },
    variant: {
        outline: 'shadow-sm bg-transparent text-gray-900 dark:text-white',
        none: 'bg-transparent',
    },
    icon: {
        color: 'text-{color}-500 dark:text-{color}-400',
        loading: 'animate-spin',
        size: {
            '2xs': 'h-4 w-4',
            xs: 'h-4 w-4',
            sm: 'h-5 w-5',
            md: 'h-5 w-5',
            lg: 'h-5 w-5',
            xl: 'h-6 w-6',
        },

        trailing: {
            wrapper: 'hidden',
        },
    },
    default: {
        size: 'sm',
        color: 'white',
        variant: 'outline',
        loadingIcon: 'i-heroicons-arrow-path-20-solid',
        trailingIcon: 'i-heroicons-chevron-down-20-solid',
    },
}
)
</script>

<style lang="scss">
.custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
}

.custom-select .selected {
    cursor: pointer
}



.custom-select .items {
    background-color: white;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 30px;
    min-width: 100px;
    padding: 12px 12px 8px;
    border-radius: 12px;
    border: 1px solid #40AB35;
}



.custom-select .items div {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
}

.custom-select .items div:not(:last-child) {
    margin-bottom: 8px;
}

.selectHide {

    display: none;

}
</style>
