<template>
  <ApplicationLayout>
    <NuxtLoadingIndicator />
    <NuxtRouteAnnouncer />
    <NuxtPage />
    <Transition appear name="fade">
      <ApplicationOverlay v-if="isLoading" @click="finish" />
    </Transition>
  </ApplicationLayout>
</template>

<script setup>
const { isLoading, finish } = useLoadingIndicator()
const rc = useRuntimeConfig()
useHead({
  script: [{
    src: 'https://iway.ru/js/plugins/iframe.resize.js',
    type: 'text/javascript'
  }],
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Qanotsharq` : 'Qanotsharq';
  },
  meta: [
    { property: 'og:site_name', content: 'Qanot Sharq Airlines' },
    { property: 'og:image', content: '/logo.png' }
  ]
})
</script>

<style lang="scss">
// @media (max-width: 1023px) {
//   body:has(.my-drawer) {
//     overflow-y: hidden;
//   }
// }

.required {
  position: relative;

  &::after {
    content: '*';
    color: red;
    position: absolute;
    left: 100%;
    top: 0;
  }
}

//  TRANSITIONS

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drawer-enter-active,
.drawer-leave-active {
  transition: transform 0.2s linear;
}

.drawer-enter-from,
.drawer-leave-to {
  transform: translateY(100%);
}

.drawer-enter-to,
.drawer-leave-from {
  transform: translateY(0);
}

.stapel {
  font-family: Stapel;
}

.inter {
  font-family: Inter;
}

.button-green:hover {
  box-shadow: 0px 6px 12px 0px #40AB3533, 0px 22px 22px 0px #40AB352B, 0px 50px 30px 0px #40AB351A, 0px 89px 36px 0px #40AB3508, 0px 139px 39px 0px #40AB3500;
}

html {
  font-family: Inter;
  font-weight: 500;
  background-color: #FCFCFD;
  color: #1C1C1C;
}

.test {
  margin-left: 16px;
}

@media (min-width:1024px) {
  .test {
    margin-left: 32px;
  }
}

@media (min-width:1344px) {
  .test {
    margin-left: calc((100% - 1280px) / 2);
  }
}

.my-container {
  margin-left: 16px;
  margin-right: 16px;
}

@media (min-width:1024px) {
  .my-container {
    margin-left: 32px;
    margin-right: 32px;
  }
}

@media (min-width:1344px) {
  .my-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}

.ticket-cutout {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%) translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: #203562;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 100px;
    background-color: #203562;
  }
}


.body-content {
  font-family: Inter;

  & .table table {
    width: 100%;
  }

  & p {
    margin-bottom: 8px;
  }

  & ul {
    list-style-type: disc;
    list-style-position: inside;

    & li {
      margin-bottom: 8px;
      margin-left: 4px;
    }
  }

  & h2,
  & h3 {
    font-weight: 600;
    margin-bottom: 8px;
  }

  & table {
    vertical-align: middle;
    border-collapse: collapse;
    table-layout: fixed;
  }

  & td {
    padding: 24px;
    border: 2px solid #E6E8EC;
  }

  &.middle {
    vertical-align: middle;
  }
}

@media (max-width: 800px) {
  .body-content {
    width: 100%;
    overflow-x: auto;

    & .table {
      width: 800px;
    }

    & tbody tr:first-child {
      position: sticky;
      top: 0;
    }
  }
}


@media (min-width: 1024px) {
  .news-recommends {
    & .news-card:nth-child(3)~.news-card {
      display: none;
    }
  }
}
</style>