<template>
    <UModal v-model="open" size="lg" :ui="{ width: 'w-full sm:max-w-2xl', }">
        <div class=" rounded-2xl overflow-hidden">
            <div class="relative p-8 bg-blue-dark-950 flex items-center justify-between text-white mb-4">
                <span>
                    {{ t('registration') }}
                </span>
                <UIcon name="ri:close-fill" size="24" @click="open = false"></UIcon>
            </div>
            <div class="p-8">
                <div class="grid grid-cols-2 mb-6 gap-6">
                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">{{ t('yourLogin') }}</div>
                        <input id="ticket" type="text"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="Login (E-mail)">
                    </label>

                    <label for="ticket" class=" block">
                        <div class=" mb-2 text-[14px]/[17px] inter">{{ t('yourPassword') }}</div>
                        <input id="ticket" type="password"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="Password">
                    </label>
                </div>
                <div class="grid grid-cols-2 mb-6 gap-6">
                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">Your Name</div>
                        <input id="ticket" type="text"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="Name">
                    </label>

                    <label for="ticket" class=" block">
                        <div class=" mb-2 text-[14px]/[17px] inter">Confirm Password</div>
                        <input id="ticket" type="text"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="Repeat Password">
                    </label>
                </div>
                <div class="grid grid-cols-2 mb-6 gap-6">
                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">Your Surname</div>
                        <input id="ticket" type="text"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="Surname">
                    </label>

                    <label for="ticket" class=" block">
                        <div class="mb-2 text-[14px]/[17px] inter">Your Phone</div>
                        <input id="ticket" type="text"
                            class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter"
                            placeholder="Phone number">
                    </label>
                </div>
                <div class="grid grid-cols-3 mb-4">
                    <input id="ticket" type="text"
                        class="border-2 border-gray-line w-full p-4 rounded-xl placeholder-gray-text outline-0 inter col-span-2"
                        placeholder="Enter Characters from the picture" />

                </div>
                <div class="flex items-center justify-between mb-4">
                    <UCheckbox v-model="ch" name="notifications" label="I Agree with terms and conditions"
                        color="green" />
                </div>
                <div>
                    <button
                        class="py-4 px-6 bg-my-green mx-auto block rounded-3xl text-white text-[14px]/[16px] inter transition-shadow button-green">
                        {{ t('registration') }}
                    </button>
                </div>
            </div>

        </div>
    </UModal>
</template>
<script setup>
const open = useState('registration')
const ch = ref(false)
</script>