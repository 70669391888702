<template>
    <div style="min-height: 100vh; display: flex; flex-direction: column;">
        <ApplicationHeader />
        <div style="flex-grow: 1; width: 100%">
            <slot></slot>
        </div>
        <ApplicationFooter />
        <UNotifications />
        <ApplicationLogin />
        <ApplicationRegistration />
    </div>
</template>